import _ from 'lodash'
// import Modernizr from 'modernizr'
import { TweenMax, TimelineMax, Power1, Power4, CSSPlugin, AttrPlugin } from 'gsap/TweenMax'
import View from 'js/Component/View';
// import Text from 'js/Component/Text'
import Animation from 'js/Component/Animation';
// import Information from 'js/Component/Information';
// import store from 'store';

class HomeView extends View {
    constructor() {
        super();
    }

    init() {
        super.init();

        this.animation = new Animation();
        // this.string = new Text();
        // this.info = new Information();

        this.render();
    }

    beforeRender() {
        super.beforeRender();

        this.setView();
        this.setObserve();

        this.animation.setHeroSlide('js-anim__slide', 'l-hero__bg');
        this.animation.setNavAnchor('.js-anim__anchor');
        this.animation.setPagetopAnchor('.js-anim__pagetop');
    }

    render() {
        super.render();
    }

    afterRender() {
        // if ( Modernizr.touchevents ) {
        //     return false;
        // }

        const self = this;
        const timeline = new TimelineMax();

        this.animation.renderHeroSlide('js-anim__slide', 'l-hero__bg');
        // this.animation.renderNavAnchor();
    }

    setView() {
        // if ( Modernizr.touchevents ) {
        //     return false;
        // }
    }

    setObserve() {
        // if ( Modernizr.touchevents ) {
        //     return false;
        // }

        // const self = this;

        // _.forEach(this.observe.element, (element, index) => {
        //     if ( $(element.watchItem).hasClass('js-heading') ) {
        //         this.animation.setHeading('.js-heading');
        //         element.fullyEnterViewport(function() {
        //             self.animation.enterHeadingInView(this.watchItem);
        //         });
        //     }

        //     if ( $(element.watchItem).hasClass('js-anim-dash') ) {
        //         this.animation.setDash(element.watchItem);
        //         element.fullyEnterViewport(function() {
        //             self.animation.renderDash(this.watchItem);
        //         });
        //     }

        //     if ( $(element.watchItem).hasClass('js-anim-typing') ) {
        //         element.fullyEnterViewport(function() {
        //             self.animation.typeWrite(this.watchItem);
        //         });
        //     }

        //     if ( $(element.watchItem).hasClass('js-anim-btn') ) {
        //         this.animation.setBtn(element.watchItem);
        //         element.fullyEnterViewport(function() {
        //             self.animation.renderBtn(this.watchItem);
        //         });
        //     }

        //     if ( $(element.watchItem).hasClass('js-anim-slidefromleft') ) {
        //         this.animation.setSlide(element.watchItem, -40);
        //         element.fullyEnterViewport(this.animation.renderSlide);
        //     }

        //     if ( $(element.watchItem).hasClass('js-anim-tags') ) {
        //         this.animation.setFilter(element.watchItem, true);
        //         element.fullyEnterViewport(this.animation.renderChildFilter);
        //     }

        //     if ( $(element.watchItem).hasClass('js-anim-filter') ) {
        //         this.animation.setFilter(element.watchItem, false, true);
        //         element.fullyEnterViewport(this.animation.renderFilter);
        //     }

        //     if ( $(element.watchItem).hasClass('js-anim-cards') ) {
        //         this.animation.setFilter(element.watchItem, true);
        //         element.enterViewport(this.animation.renderChildFilter);
        //     }
        // });
    }
}

export default HomeView;
