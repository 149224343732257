// import Modernizr from 'modernizr'
import { TweenMax, TimelineMax, Power1, Power4, CSSPlugin } from 'gsap/TweenMax'
import CSSRulePlugin from 'gsap/CSSRulePlugin'
import 'js/Vendor/typing'
import Text from 'js/Component/Text'

class Animation {
    constructor() {
    }

    init() {
    }

    setHeroSlide(wrapper, cls){
        let slideWrap          = $('.'+wrapper);
        let slideContentsCount = 3;
        let slideFirstContents;
        
        for(var i = 0; i < slideContentsCount; i++){
            slideFirstContents = i === 0 ? ' '+cls+'--active' : '' ;
            slideWrap.append(`<div class="${cls} ${cls}--${i+1}${slideFirstContents}">`);
        }
    }

    renderHeroSlide(wrapper, cls){
        let slideContent        = $('.'+cls);
        let slideContentsCount  = 3;
        let slideActivePosition = 0;

        setInterval(function(){
            slideContent.eq(slideActivePosition).removeClass(cls+'--active');
            slideActivePosition = slideActivePosition < slideContentsCount-1 ? slideActivePosition+=1 : 0 ;
            slideContent.eq(slideActivePosition).addClass(cls+'--active');
        }, 6000);
    }

    setNavAnchor(element){
        /* hash remove */
        // if(location.pathname === '/'){
        //     window.history.replaceState(null, null, location.origin);
        // }

        /* anchor */
        $(document).on('click', element, (e) => {
            let destination = $(e.target).data('destination');
            if(!destination) return true ;

            /* scroll */
            let targetPosition = $(`[data-anchor="${destination}"]`).position().top;
            let headerMargin   = $('header').height()
            let scrollValue    = targetPosition-headerMargin+1;
            $('html, body').animate({scrollTop: scrollValue}, 500);

            /* sp nav hidden */
            $('.l-gnav__checkbox')[0].checked = false;
        });
    }

    setPagetopAnchor(element){
        $(document).on('click', element, () => {
            $('html, body').animate({scrollTop: 0}, 500);
        });
    }
}

export default Animation;
