var cache = {};

function importAll (r) {
    r.keys().forEach(key => cache[key] = r(key));
}

importAll(require.context('../images/', true, /\.(png|jpe?g|svg|icon)$/));
importAll(require.context('material-design-icons/iconfont/', true, /\.(eot|ttf|woff2?)$/));
importAll(require.context('../php/', true, /\.php$/));
importAll(require.context('../mail/', true, /\.tpl$/));

import '../scss/home.scss'
import View from 'js/View/HomeView'

const view = new View();
view.init();
