// import Scroll from 'js/Component/Scroll';
// import Touch from 'js/Component/Touch';
// import Observe from 'js/Component/Observe';

class View {
    constructor() {
    }

    init() {
        // this.scroll = new Scroll();
        // this.touch = new Touch();
        // this.observe = new Observe();
    }

    beforeRender() {
        // this.observe.init();
        // this.scroll.initSmooth(1.2, 100);
        // this.scroll.initToTop();
        // this.touch.initTouch();
        // this.nav.init();
    }

    render() {
        this.beforeRender();

        $(window).on('load', () => {
            this.afterRender();
        });
    }

    afterRender() {
    }
}

export default View;